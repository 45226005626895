import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { ApiChangeAgreementRequest, ApiChangeAgreementRequestMapper } from "@/api/bar/types/agreement/apiChangeAgreementRequest";
import { AccountAgreement } from "@/store/bar/types/accountAgreement";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiChangeAccountAgreementRequest {
	accountType: BankAccountTypeEnum;
	startDate: string;
	agreement: ApiChangeAgreementRequest;
}

export class ApiChangeAccountAgreementRequestMapper {
	static map(source: AccountAgreement): ApiChangeAccountAgreementRequest {
		return {
			accountType: source.accountType,
			startDate: formatDate(source.startDate, isoDateFormat).toString(),
			agreement: source.agreement ? ApiChangeAgreementRequestMapper.map(source.agreement) : { number: source.number } as ApiChangeAgreementRequest
		};
	}
}