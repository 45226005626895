<template>
	<v-row class="d-flex flex-column" v-if="isInitialized">
		<bar-bank-account-application-select-next-action-dialog v-model="isSelectNextDialogOpened"
																:description1="description1"
																:description2="description2"
																@click:no="isAccountTypeChangeNoticeDialogOpened = true"
																@click:yes="isFutureAccountUsageDialogOpened = true">
		</bar-bank-account-application-select-next-action-dialog>
		
		<bar-bank-account-application-existing-request-warning-dialog v-model="isExistingRequestWarningDialogOpened"
																	  :existingApplications="existingApplications"/>
		
		<bar-bank-account-application-account-type-change-notice-dialog v-model="isAccountTypeChangeNoticeDialogOpened">
		</bar-bank-account-application-account-type-change-notice-dialog>
		
		<bar-bank-account-application-primary-account-conflict-warning-dialog v-model="isPrimaryAccountConflictWarningDialogOpened">
		</bar-bank-account-application-primary-account-conflict-warning-dialog>
		
		<bar-bank-account-application-future-account-usage-dialog v-if="isFutureAccountUsageDialogOpened"
																  v-model="isFutureAccountUsageDialogOpened"
																  @click="createOrUpdateBankAccountApplication">
		</bar-bank-account-application-future-account-usage-dialog>
		
		<v-col class="pb-1">
			<bar-bank-account-application-information-base/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-organization-data/>
		</v-col>
		<v-col class="pb-1">
			<bar-bank-account-application-information-bank-editable/>
		</v-col>
		<v-col>
			<bar-bank-account-application-information-accounts-editable/>
		</v-col>
	</v-row>
	<bar-bank-account-application-information-editable-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes, getterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import BarBankAccountApplicationAccountTypeChangeNoticeDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationAccountTypeChangeNoticeDialog.vue";
import BarBankAccountApplicationExistingRequestWarningDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationExistingRequestWarningDialog.vue";
import BarBankAccountApplicationFutureAccountUsageDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationFutureAccountUsageDialog.vue";
import BarBankAccountApplicationPrimaryAccountConflictWarningDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationPrimaryAccountConflictWarningDialog.vue";
import BarBankAccountApplicationSelectNextActionDialog
	from "@/views/bar/bankAccountApplication/dialogs/BarBankAccountApplicationSelectNextActionDialog.vue";
import BarBankAccountApplicationInformationEditableLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformationEditableLoader.vue";
import BarBankAccountApplicationInformationAccountsEditable
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/accounts/BarBankAccountApplicationInformationAccountsEditable.vue";
import BarBankAccountApplicationInformationBankEditable
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/bank/BarBankAccountApplicationInformationBankEditable.vue";
import BarBankAccountApplicationInformationBase
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/baseInformation/BarBankAccountApplicationInformationBase.vue";
import BarBankAccountApplicationInformationOrganizationData
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/organizationData/BarBankAccountApplicationInformationOrganizationData.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	props: {
		type: String,
		triggerAccountUsageProcessing: Boolean
	},
	data() {
		return {
			namespace,
			RouteNames,
			isSelectNextDialogOpened: false,
			isAccountTypeChangeNoticeDialogOpened: false,
			isFutureAccountUsageDialogOpened: false,
			isPrimaryAccountConflictWarningDialogOpened: false,
			isExistingRequestWarningDialogOpened: false,
			description1: {},
			description2: {},
			watchedOnce: false,
			excludedAccountIds: []
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isRecordUniqueCheckInProgress: state => state.form.isRecordUniqueCheckInProgress,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isBankAccountApplicationBankFormValid: state => state.isBankAccountApplicationBankFormValid,
			isBankAccountApplicationAccountsFormValid: state => state.isBankAccountApplicationAccountsFormValid,
			accountUsages: state => state.accountUsages,
			editableItem: state => state.editableItem,
			banks: state => state.banks,
			applicationBankAccountUsageTypes: state => state.applicationBankAccountUsageTypes,
			currencies: state => state.currencies,
			existingApplications: state => state.existingApplications
		}),
		...mapGetters({
			getBankName: getterTypes.getBankName
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({
			createBankAccountApplication: actionTypes.createBankAccountApplication,
			updateBankAccountApplication: actionTypes.updateBankAccountApplication,
			fetchAccountUsages: actionTypes.fetchAccountUsages,
			fetchExistingApplications: actionTypes.fetchExistingApplications
		}),
		async createOrUpdateBankAccountApplication() {
			switch (this.type) {
				case "create":
				{
					await this.createBankAccountApplication();
					break;
				}
				case "update":
				{
					await this.updateBankAccountApplication();
					break;
				}
			}
		},
		async handleOpenFutureAccountUsageDialog() {
			this.$emit("update:trigger-account-usage-processing", false);
			
			if(this.type === "create") {
				await this.fetchExistingApplications(this.excludedAccountIds);
				
				if(this.existingApplications?.length) {
					this.isExistingRequestWarningDialogOpened = true;
					return;
				}
			}
			
			if(this.hasMultiplePrimaryAccounts(this.editableItem.accounts)) {
				this.isPrimaryAccountConflictWarningDialogOpened = true;
				return;
			}
			
			await this.fetchAccountUsages();
			
			if(!this.editableItem.accountUsages?.length) {
				await this.createOrUpdateBankAccountApplication();
			} else {
				this.updateDescriptions();
				this.isSelectNextDialogOpened = true;
			}
		},
		updateDescriptions() {
			if(!this.editableItem.accountUsages?.length) return;
			
			let accountPrimaryNumber = null;
			let bankName = null;
			
			for (const bank of this.editableItem.accountUsages) {
				accountPrimaryNumber = bank.items.find(x => x.accountType === BankAccountTypeEnum.PRIMARY)?.accountNumber;
				
				if(accountPrimaryNumber) {
					bankName = this.getBankName(bank.bankId);
					break;
				}
			}
			
			this.description1 = {
				loanApplicationNumber: this.editableItem.loanApplicationNumber,
				accountPrimaryNumber,
				bankName
			};
			
			this.description2 = {
				currentAccountPrimaryNumber: this.editableItem.accounts.find(
					x => x.accountType === BankAccountTypeEnum.PRIMARY)?.accountNumber,
				currentBankName: this.getBankName(this.editableItem.bankId)
			};
		},
		hasMultiplePrimaryAccounts(accounts) {
			let primaryCount = 0;
			
			for (const { accountType } of accounts) {
				if(accountType === BankAccountTypeEnum.PRIMARY) primaryCount++;
				
				if(primaryCount > 1) return true;
			}
			
			return false;
		}
	},
	watch: {
		triggerAccountUsageProcessing(value) {
			if(value) {
				this.handleOpenFutureAccountUsageDialog();
			}
		},
		"editableItem.accounts": {
			handler(value) {
				/**
				 * Нужен для правильной обработки счетов при редактировании заявки
				 * Исключаем уже существующие счета, чтобы не было лишних проверок
				 * Выполняется только один раз
				 *
				 * @note watchedOnce нужен для имитации watchOnce
				 * Поле once в watch поддерживается во vue с версией 3.4+
				 */
				if(this.watchedOnce || this.type !== "update") return;
				
				this.excludedAccountIds = value.map(x => x.id);
				this.watchedOnce = true;
			},
			immediate: true
		}
	},
	components: {
		BarBankAccountApplicationExistingRequestWarningDialog,
		BarBankAccountApplicationAccountTypeChangeNoticeDialog, BarBankAccountApplicationSelectNextActionDialog, BarBankAccountApplicationPrimaryAccountConflictWarningDialog, BarBankAccountApplicationFutureAccountUsageDialog,
		BarBankAccountApplicationInformationEditableLoader,
		BarBankAccountApplicationInformationBase,
		BarBankAccountApplicationInformationOrganizationData,
		BarBankAccountApplicationInformationBankEditable,
		BarBankAccountApplicationInformationAccountsEditable,
		FrpIcon,
		FrpBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
