import { BankAccountStatusTypeEnum } from "@/store/bar/types/BankAccountStatusTypeEnum";
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";

export default class BankAccountsListFilter {
	constructor(
		public loanApplicationNumber: string = "",
		public counterpartyId: string = "",
		public bankId: string = "",
		public signStatuses: BankAccountStatusTypeEnum[] = [],
		public responsibleUserId: string = "",
		public monitoringSystemConnectionStatuses: BankAccountMonitoringSystemConnectionStatusTypeEnum[] = []
	)
	{
	}
}
