<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.existingRequestWarning.title')"
				max-width="600"
				max-height="400px"
				scrollable
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<p v-for="{ number, existingAccounts } of existingApplications" :key="number">
				{{
					$t("dialogs.existingRequestWarning.description",
						{ applicationNumber: number, accountNumber: existingAccounts[0].number })
				}}
			</p>
		</template>
		
		<template #footer>
			<frp-btn elevation="0"
					 color="blue"
					 dark
					 @click="$emit('update:value', false)">
				{{ $t("buttons.ok") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		existingApplications: Array
	},
	data() {
		return {};
	},
	methods: {},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
