<template>
	<span :style="{ color }">{{ text }}</span>
</template>

<script>
import { BankAccountMonitoringSystemConnectionStatusTypeEnum } from "@/store/bar/types/BankAccountMonitoringSystemConnectionStatusTypeEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		statuses: {
			type: Array
		},
		status: {
			type: [String, BankAccountMonitoringSystemConnectionStatusTypeEnum]
		}
	},
	computed: {
		text() {
			if (this.status === BankAccountMonitoringSystemConnectionStatusTypeEnum.UNKNOWN) {
				return ""
			}
			return this.statuses?.find(x => x.code === this.status)?.title || "";
		},
		color() {
			switch (this.status) {
				case BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_CONNECTED:
					return this.colors.warning.base;
				case BankAccountMonitoringSystemConnectionStatusTypeEnum.DISCONNECTED:
					return this.colors.secondary.base;
				case BankAccountMonitoringSystemConnectionStatusTypeEnum.NOT_REQUIRED:
					return this.colors.blue.base;
				case BankAccountMonitoringSystemConnectionStatusTypeEnum.CONNECTED:
					return this.colors.blue.base;
				case BankAccountMonitoringSystemConnectionStatusTypeEnum.TO_DISCONNECT:
					return this.colors.warning.base;
			}
		}
	}
};
</script>
