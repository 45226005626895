<template>
	<div class="bar-details-item px-4">
		<template v-if="title">
			<v-row class="d-flex align-center">
				<slot name="prepend"></slot>
				<v-col>
					<v-tooltip v-if="link" bottom :color="colors.blue.base" :disabled="!tooltip">
						<template v-slot:activator="{ on, attrs }">
							<frp-text-btn :to="to"
										  v-bind="attrs"
										  v-on="on"
										  :wrap="wrapLink"
										  class="pa-0 bar-text-btn"
										  :text="title"
										  @click="$emit('click')"
										  color="primary">
							</frp-text-btn>
						</template>
						<span>{{ tooltip }}</span>
					</v-tooltip>
					
					<span v-else class="bar-details-item-title text-body-2 primary--text text--darken-4">{{ title }}</span>
				</v-col>
				
				<slot name="append"/>
			</v-row>
			
			<v-spacer></v-spacer>
		</template>
		
		<slot></slot>
	</div>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import colorsMixin from "@/mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	components: { FrpIcon, FrpBtn, FrpTextBtn },
	props: {
		title: String,
		link: {
			type: Boolean,
			default: false
		},
		wrapLink: {
			type: Boolean,
			default: false
		},
		to: Object,
		tooltip: String
	}
};
</script>

<style scoped lang="scss">
</style>
