<template>
	<span :style="{ color }">{{ text }}</span>
</template>

<script>
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import colorsMixin from "Mixins/colorsMixin";

export default {
	mixins: [colorsMixin],
	props: {
		statuses: {
			type: Array
		},
		status: {
			type: [String, BankAccountApplicationStatusTypeEnum]
		}
	},
	computed: {
		text() {
			return this.statuses.find(x => x.code === this.status)?.title || "";
		},
		color() {
			switch (this.status) {
				case BankAccountApplicationStatusTypeEnum.IN_PROGRESS:
					return this.colors.warning.base;
				case BankAccountApplicationStatusTypeEnum.COMPLETED:
					return this.colors.green.base;
			}
		}
	}
};
</script>
