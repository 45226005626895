export enum BankAccountStatusTypeEnum {
	UNKNOWN = "Unknown",
	NEW = "New",
	TO_CONNECT = "ToConnect",
	RESPONSIBLE_ASSIGNED = "ResponsibleAssigned",
	CONNECTED = "Connected",
	DISCONNECTED = "Disconnected",
	TO_DISCONNECT = "ToDisconnect",
	WORK_STOPPED = "WorkStopped",
}
