import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { ApiBankAccountTypeHistory } from "@/api/bar/types/apiBankAccountTypeHistory";
import { convertToTimestamp, convertToZonedTimestamp } from "@/utils/dates";

export interface BankAccountTypeHistory {
	creatorFullName: string,
	accountType: BankAccountTypeEnum,
	startDate: number,
	createdAt: number
}

export class BankAccountTypeHistoryMapper {
	static map(source: ApiBankAccountTypeHistory): BankAccountTypeHistory {
		return {
			...source,
			startDate: convertToTimestamp(source.startDate) as number,
			createdAt: convertToZonedTimestamp(source.createdAt) as number
		};
	}
}