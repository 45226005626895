<template>
	<frp-card :title="$t('titles.bank')" v-if="isInitialized">
		<template #actions v-if="!isBarUserBorrower && NO_DOCS_REQUIRED_BANK_IDS.includes(editableItem.bankId)">
			<span style="font-size: 14px" :style="{ color: colors.red.base }">
				{{ $t("common.noDocumentsRequiredForBank") }}
			</span>
		</template>
		
		<template #content>
			<bar-details-group>
				<bar-details-item :title="$t('details.titles.bank')">
					<span class="text-subtitle-2 font-weight-medium primary--text">
						{{ banks.find(x => x.id === editableItem.bankId)?.name || "" }}
					</span>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.bankBranch')">
					<span class="text-subtitle-2 font-weight-medium primary--text">
						{{ bankBranches.find(x => x.id === editableItem.bankBranchId)?.name || "" }}
					</span>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.bik')">
					<span class="text-subtitle-2 font-weight-medium primary--text">{{ bik }}</span>
				</bar-details-item>
				
				<bar-details-item :title="$t('details.titles.correspondentAccount')">
					<span class="text-subtitle-2 font-weight-medium primary--text">{{ correspondentAccount }}</span>
				</bar-details-item>
			</bar-details-group>
		</template>
	</frp-card>
	<bar-bank-account-application-information-bank-loader v-else/>
</template>

<script>
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { actionTypes, mutationTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import storeManager from "@/store/manager";
import BarBankAccountApplicationInformationBankLoader
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/sections/bank/BarBankAccountApplicationInformationBankLoader.vue";
import { createNamespacedHelpers } from "vuex";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { NO_DOCS_REQUIRED_BANK_IDS } from "@/constants/bar/noDocsRequiredBankIds";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

export default {
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			formatDate,
			dateFormat,
			NO_DOCS_REQUIRED_BANK_IDS
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem,
			banks: state => state.banks,
			bankBranches: state => state.bankBranches,
			bik: state => state.bik,
			correspondentAccount: state => state.correspondentAccount,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading
		}),
		...barUserModuleHelpers.mapGetters({
			isBarUserBorrower: barUserModuleGetterTypes.isBarUserBorrower
		})
	},
	methods: {
		...mapMutations({}),
		...mapActions({})
	},
	components: { BarBankAccountApplicationInformationBankLoader, FrpDateField, FrpTextField, FrpAutocomplete, BarDetailsItem, BarDetailsGroup, FrpCard }
};
</script>

<style scoped>

</style>
