import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { ApiSuggestedProject } from "@/api/bar/types/apiSuggestedProject";
import { ApiAccountAgreement } from "@/api/bar/types/accountAgreement/apiAccountAgreement";
import { ApiAgreement } from "@/api/bar/types/agreement/apiAgreement";
import { convertToTimestamp } from "@/utils/dates";

export interface AccountAgreement {
	number: number;
	accountType: BankAccountTypeEnum;
	agreement?: ApiAgreement;
	startDate: number;
	olkCounterpartyId: string;
	suggestedProjects: ApiSuggestedProject[];
	isNewItem: boolean;
	isFetchProjectsLoading: boolean;
	isNumberUniqueCheckInProgress: boolean;
	isNumberUnique: boolean;
}

export class AccountAgreementHelper {
	static map(source: ApiAccountAgreement): AccountAgreement {
		return {
			number: source.agreement.number || 0,
			accountType: source.accountType,
			startDate: convertToTimestamp(source.startDate) as number,
			agreement: source.agreement,
			olkCounterpartyId: source?.agreement?.counterparty?.id || "",
			suggestedProjects: [],
			isNewItem: false,
			isFetchProjectsLoading: false,
			isNumberUniqueCheckInProgress: false,
			isNumberUnique: true
		};
	}
	
	static getEmpty(): AccountAgreement {
		return {
			number: 0,
			accountType: BankAccountTypeEnum.UNKNOWN,
			startDate: 0,
			olkCounterpartyId: "",
			suggestedProjects: [],
			isNewItem: true,
			isFetchProjectsLoading: false,
			isNumberUniqueCheckInProgress: false,
			isNumberUnique: true
		};
	}
}